import Image from "next/image";
import Link from "next/link";

import MobileNavigation from "./mobileNav";
import { companyInfoQueryResponse } from "@/lib/sanityDangerousTypes";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { navLinks } from "@/lib/constants";
import { useRouter } from "next/router";
import { cn } from "@/lib/utils";

export default function Header({
  companyInfo,
}: {
  companyInfo: companyInfoQueryResponse | undefined;
}) {
  const route = useRouter();
  const { logoLight, name } = companyInfo || {};
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="sticky top-0 z-10 w-full bg-blue isolate">
      <nav
        className="flex items-center p-4 shadow-md lg:px-12"
        aria-label="Global"
      >
        <div className="block mr-1 xl:mr-4 xl:block">
          <Link href="/">
            {logoLight && (
              <Image
                alt={`${name} logo`}
                height={540}
                priority={true}
                src={logoLight}
                width={720}
                className="w-auto h-14 xl:h-24"
              />
            )}
          </Link>
        </div>
        <div className="flex justify-end flex-1">
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon color="white" className="w-8 h-8" aria-hidden="true" />
            </button>
          </div>
          <div className="items-center justify-start hidden max-w-4xl lg:justify-between lg:flex-wrap first-letter lg:flex xl:space-between lg:flex-1">
            {navLinks.map((link) => (
              <Link
                key={link.label}
                href={link.href}
                className={cn(
                  "mx-1 xl:mx-2 text-sm font-medium leading-6 tracking-normal xl:tracking-wide text-white no-underline uppercase transition-colors duration-200 hover:text-orange-600 font-display",
                  route.asPath === link.href ? "text-orange-400" : ""
                )}
              >
                {link.label}
              </Link>
            ))}
          </div>
          <div className="hidden lg:flex lg:justify-end">
            <Link
              href="/search"
              className="px-4 py-4 ml-2 text-sm font-semibold text-center text-white no-underline uppercase transition-colors duration-200 rounded-full xl:tracking-wide xl:ml-12 xl:px-10 bg-orange font-display hover:bg-orange/85"
            >
              Property Search
            </Link>
          </div>
        </div>
      </nav>
      <MobileNavigation
        companyInfo={companyInfo}
        menuOpen={mobileMenuOpen}
        setMenuOpen={setMobileMenuOpen}
      />
    </header>
  );
}
