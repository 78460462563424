import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import Link from "next/link";

import { companyInfoQueryResponse } from "@/lib/sanityDangerousTypes";
import { getNavigationFromCompany } from "@/lib/constants";

export default function Footer({
  companyInfo,
}: {
  companyInfo: companyInfoQueryResponse | undefined;
}) {
  const { logoLight, name } = companyInfo || {};

  const navigation = getNavigationFromCompany({ companyInfo });

  return (
    <footer className="bg-blue" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-6 pt-12 pb-8 mx-auto max-w-7xl sm:pt-16 lg:px-8 lg:pt-24">
        <div className="flex flex-col justify-center xl:flex-row xl:gap-x-36">
          <div className="space-y-8">
            <div className="flex justify-center">
              <Link href="/">
                {logoLight && (
                  <Image
                    alt={`${name} logo`}
                    height={540}
                    priority={true}
                    src={logoLight}
                    width={720}
                    className="w-auto h-28 lg:h-44"
                  />
                )}
              </Link>
            </div>

            <div className="flex justify-center space-x-3">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-100 hover:text-gray-400"
                >
                  <span className="sr-only">{item.name}</span>
                  <FontAwesomeIcon
                    aria-hidden="true"
                    className="w-10 h-10 text-gray-400"
                    icon={item.iconDef}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-8 mt-16 xl:mt-0 md:grid-cols-3">
            <div className="md:flex md:justify-center">
              <div>
                <h3 className="mb-4 text-lg font-semibold text-white">
                  Contact
                </h3>
                <ul className="space-y-2">
                  {navigation.contact.map((item) => (
                    <li key={item.href}>
                      <Link
                        href={item.href}
                        className="flex flex-row text-sm leading-6"
                      >
                        <FontAwesomeIcon
                          aria-hidden="true"
                          className="w-4 h-4 pt-1 mr-2 text-gray-300"
                          icon={item.iconDef}
                        />
                        <div className="text-sm leading-6 text-gray-300 hover:text-white">
                          {item.label.map((line) => (
                            <div key={line}>{line}</div>
                          ))}
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:flex md:justify-center">
              <div>
                <h3 className="mb-4 text-lg font-semibold text-white">
                  Solutions
                </h3>
                <ul className="space-y-2">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:flex md:justify-center">
              <div>
                <h3 className="mb-4 text-lg font-semibold text-white">
                  Company
                </h3>
                <ul className="space-y-2">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-sm leading-6 text-gray-300 hover:text-white"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center pt-8 mt-12 border-t border-white/10 sm:mt-20 lg:mt-24">
          <Image
            alt="Association logos"
            height={19}
            src="https://d3w216np43fnr4.cloudfront.net/8388/122996/1.png"
            title="Association logos"
            width={109}
          />
          <Link
            href="/privacy-policy"
            className="ml-4 text-xs text-gray-400 uppercase"
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </footer>
  );
}
